import React from 'react'

const HomeCare = () => {
    return (
        <div>
            <div className="about container-fluid">
                <h1 data-aos="fade-right" data-aos-duration="2000">
                    Home Care
                </h1>
                <hr />
                <div className="mainAbout">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mainHomeCareImage" data-aos="zoom-out" data-aos-duration="2000">
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="mainAboutText">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    AEROMETIC brings unique solutions to the world of HOME CARE PRODUCTS. Aerometic has extensive and immaculate experience with manufacturing and packaging of home care products of a wide range in our fully equipped facility. Globally, consumers are concerned about the performance of these products while those being sustainable. We ensure that our products lead to the least possible environmental damage and are cruelty free. We seek to make your everyday life easier by our innovative thinking. We take extensive care from what goes into our products to the way we package them, in order to not just reduce the environmental footprint but also assure that they are affordable, effective, and kinder to the end user. The products in the household cleaners’ segment are subdivided into surface cleaners, glass cleaners, and specialty cleaners.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mainAboutTxt">
                        <p data-aos="fade-left" data-aos-duration="2000">
                            We have enlisted Home Care products in these few categories:
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Household Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Laundry Care
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Dishwashing
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        WC Care
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Air Care
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Car Cleaner
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <h1 data-aos="fade-right" data-aos-duration="2000">
                            OUR PRODUCT RANGES:
                        </h1>
                        <hr />
                        <div className="row">
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Kitchen Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Bathroom Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Scouring Creams (Cream Cleaners)
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Fabric Softner (Regular and Concentrated)
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Bathroom Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        All-purpose Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Bleach
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Furniture Care and Polish Sprays (Liquid)
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Stainless Steel Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Laundry Liquids
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Perfumed Capsules
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Hand Dishwashing Liquid
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Car Multipurpose Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Toilet Cleaners with or without Bleach
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Car Window Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Fridge Odour Absorber
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Degreasers (Regular & Extra Power)
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Wooden Floor & Furniture Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Ultra Thick Bleach
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Fabric Disinfectant
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Degreasers with Marseille Soap
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Hard Surface Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Stain Removers
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Oxy-Stain Removers
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Toilet Seat Disinfectant
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Dishwashing Powder
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Wheel Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Dashboard Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Dishwasher Deodorant
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Car Fresheners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Dishwasher Salt
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Vinegar Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Drain Openers (Granular & Gel)
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Fridge & Microwave Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Odor Removers
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Fabric Freshners (Laundary & Freshners)
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Shower Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Carpet CLeaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Carpet Shampoos
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Water Softeners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Easy Ironing Water
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Vitro-Ceramic Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Glass & Window Cleaners
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Wet Wipes
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Rinse Aids
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Air Fresheners (Liquid, gels, beads)
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeCare
