import React from 'react'

const Aerosol = () => {
    return (
        <div>
            <div className="about container-fluid">
                <h1 data-aos="fade-right" data-aos-duration="2000">
                    Aerosol
                </h1>
                <hr />
                <div className="mainAbout">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mainAerosolImage" data-aos="zoom-out" data-aos-duration="2000">
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="mainAboutText">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    Whether your company is launching a new product and needs an expert source to help right from the formulation stage, or needs a strategic partner to manage the entire filling process with precise packaging, Aerometic offers over 20 years of specialized experience in Aerosol Products. Our typical fill sizes range from 0.25 oz up to 8 oz. We work with customers through formulation and product development, testing/validation, filling, packaging and delivery to fill a variety of product sizes from small sample and travel size as low as 0.2 oz., up to larger Home-careproducts of 8 oz., with valve sizes from 20 mm up to 1 inch.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mainAboutTxt">
                        <p data-aos="fade-left" data-aos-duration="2000">
                            We have enlisted Aerosol products in these few categories:
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Personal Care Products
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Household Cleaning Products
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Technical Sprays
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Car Maintenance Sprays
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Other Future Planning
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <h1 data-aos="fade-right" data-aos-duration="2000">
                            OUR PRODUCT RANGES:
                        </h1>
                        <br />
                        <h3 data-aos="fade-right" data-aos-duration="2000">
                            Personal Care Products:
                        </h3>
                        <br />
                        <div className="row">
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Deodrant
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Deodorant-Antiperspirant
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Perfume
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Roll-on
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Hair Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Hair Mousse
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Shower Gel
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Hand Sanitizer
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Shaving Foam
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Shaving Gel
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Gtter Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Color Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Foot Deodorant
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Nail Polish Drying Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Cologne Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Micellar Water
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Legs Foundation
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Sun Protecting Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Shoe-Shining Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Spring Water
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Mineral Water Facial Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Shower Foam
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Non Alcoholic Deodorant
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <h3 data-aos="fade-right" data-aos-duration="2000">
                            Household Cleaning Products:
                        </h3>
                        <br />
                        <div className="row">
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Carpet and Fabric Cleaner
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Built and Enamel CLeaner
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Seat and Sofa Cleaner
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Wooden Furniture Cleaner
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Glass Cleaner
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Automatic Refill Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Refrigerator Cleaner
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Stain Remover Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Surface Sanitizer
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Tiles & Ceramic Cleansing Foam
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        WC Cleaner
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Air Freshener / Natural Air Freshner
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <h3 data-aos="fade-right" data-aos-duration="2000">
                            Technical Sprays:
                        </h3>
                        <br />
                        <div className="row">
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Computer Cleaner Spray / Foam
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Rust Remover
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Oil Cleaner
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Silicone Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Injector Cleanser
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Air Conditioner Cleaner Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Disinfectant Spray
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <h3 data-aos="fade-right" data-aos-duration="2000">
                            Car Maintenance Sprays:
                        </h3>
                        <br />
                        <div className="row">
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Air Conditioner Cleaner Spray
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Stain Prevention Sprays
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Brake Lining Sprays
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Auto Perfumes
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Torpedo Polish Spray
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Carburetor Sprays
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Tyre Sprays
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <h3 data-aos="fade-right" data-aos-duration="2000">
                            Other Future Planning:
                        </h3>
                        <br />
                        <div className="row">
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Aerosol Products with Nitrogen
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Products with dimethyl ether
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Aerosol Foams
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Compressed Aerosol Products
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Oxygen Filling
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Aerosol Cream Products
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Powdered Aerosols
                                    </strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Bag-on Valve Products
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Aerosol Gels
                                    </strong>
                                </p>
                                <p data-aos="fade-left" data-aos-duration="2000">
                                    <i className="fas fa-spray-can"></i>
                                    <strong>
                                        Special Aerosol Products (Cooling Sprays)
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aerosol
